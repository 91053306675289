import React from 'react';

import { ColorModeSwitcher } from './settings/ColorModeSwitcher';
import { Center, Flex, Grid, GridItem, Heading, VStack } from '@chakra-ui/react';


function App() {
  return (
    <Flex justify='center'>
      <VStack>
        <ColorModeSwitcher right={0}/>
        <Heading >Soon!!</Heading>
      </VStack>
    </Flex>
  );
}

export default App;
